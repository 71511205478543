import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  Facebook,
  Twitter,
  Pinterest,
  Whatsapp,
  Linkedin,
  Envelope,
  Chain,
} from "../icons"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"
import Banner, { ShareBanner } from "../templates/banner"
const ShareLinks = ({ url, title, data }) => {
  // Handling link copy
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const onCopyText = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 10000)
  }
  console.log(data, 'datadatadatadata')
  // function to open share window
  const openShareWindow = function (e) {
    e.preventDefault()
    const { href, title } = e.currentTarget
    window.open(href, title, "width=700,height=400")
  }
  function connectString(string) {
    const arr = string.toLowerCase().split(" ");
    return arr.join("-");
  }
  const {i18n} = useTranslation()
  return (
    <div className="share-wrap">
      <div>
        <div className="share-title h5 text-center">{t("Share this page")}:</div>
        <div className="share-links flex">
          <a
            className="facebook"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            title="Share on Facebook"
            onClick={openShareWindow}
          >
            <Facebook />
          </a>
          <a
            className="twitter"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`}
            onClick={openShareWindow}
            title="Share on X"
          >
            <Twitter />
          </a>
          {/* whatsapp */}
          <a
            className="whatsapp"
            href={`whatsapp://send?text=${url}}`}
            data-action="share/whatsapp/share"
            onClick={openShareWindow}
            title="Share on Whatsapp"
          >
            <Whatsapp />
          </a>
          {/* linkedin */}
          <a
            className="linkedin"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`}
            onClick={openShareWindow}
            title="Share on LinkedIn"
          >
            <Linkedin />
          </a>
          {/* email */}
          <a
            href={`mailto:?subject=${title}&amp;body=${url}`}
            title="Send via email"
            target="_blank"
            rel="noreferrer"
          >
            <Envelope />
          </a>
        </div>
        <div
          className={`js-notification-copy-link text-center ${isCopied ? "visible" : ""
            }`}
        >
          <span>The link has been Copied to clipboard!</span>
        </div>
      </div>
      <ShareBanner/>
    </div>
  )
}

export default ShareLinks;
