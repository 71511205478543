import React, { useState, useEffect, useRef } from 'react';
import { MdPlayArrow, MdPause, MdDownload, MdRefresh } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import AudioProgressBar from './AudioProgressBar';
import { getAudioDurationInSeconds } from '@remotion/media-utils';
import { formatDurationDisplay } from '../audio/util';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PopupDownloadLink from '../PopupDownloadLink';

const useWindowSize = () => {
  const isSSR = typeof window === 'undefined';
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 0 : window.innerWidth,
    height: isSSR ? 0 : window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (!isSSR) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isSSR]);

  return windowSize;
};

const TrackItem = ({
  title = '',
  author = '',
  trackNumberLabel = '',
  selected = false,
  onClick = () => { },
  link_url = '',
  playing = false,
  progress = 0,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [trackTime, setTrackTime] = useState('');
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const { width } = useWindowSize();

  const dropDownRef = useRef();
  const activatorRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      if (!link_url) return;

      try {
        setIsLoading(true);
        const seconds = await getAudioDurationInSeconds(link_url);
        setDuration(seconds);
        setTrackTime(formatDurationDisplay(seconds));
      } catch (error) {
        console.error('Error fetching audio duration:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selected || isShown) {
      fetchData();
    }
  }, [link_url, selected, isShown]);

  useEffect(() => {
    if (!dropDownRef.current || !activatorRef.current) return;

    const handleClickOutside = (event) => {
      if (
        dropDownRef.current.contains(event.target) ||
        activatorRef.current.contains(event.target)
      ) {
        return;
      }

      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  const handleRefreshClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleDownloadClick = (event) => {
    event.stopPropagation();
  };

  const handleDropdownClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const isExternalLink = link_url.startsWith('https');

  const linkComponent = isExternalLink ? (
    <PopupDownloadLink downloadLink={link_url}>
      <button className="btn sbtn" onClick={handleDownloadClick}>
        <MdDownload size={24} />
      </button>
    </PopupDownloadLink>
  ) : (
    <Link
      hrefLang={i18n.language}
      to={link_url}
      className="btn sbtn"
      onClick={handleDownloadClick}
    >
      <MdDownload size={24} />
    </Link>
  );

  return (
    <LazyLoad height={50} offset={100}>
      <div>
        <ul>
          <li
            onClick={onClick}
            onMouseEnter={() => width > 600 && setIsShown(true)}
            onMouseLeave={() => width > 600 && setIsShown(false)}
            className={cn('list-group', {
              'bg-cyan-600 text-white list-group-selected': selected,
              'hover:bg-cyan-600 hover:text-white': !selected,
            })}
          >
            <div className="item-content">
              <div className="item-content--material">
                <div className="item-content--ppcontrol">
                  {selected && playing ? <MdPause size={20} /> : <MdPlayArrow size={20} />}
                </div>
                <div className="item-content--meta">
                  <div className="item-content--meta-title">
                    {trackNumberLabel} - {t(title)}
                  </div>
                  <div className="item-content--meta-artist">{t(author)}</div>
                </div>
              </div>
              {width > 600 && (
                <div className="item-content--actions">
                  <div className="item-content--actions-wrapper">
                    <div className={cn('action-btn', { invisible: !isShown })}>
                      <Link
                        href={`${window.location.pathname}${title.toLowerCase().replace(/ /g, '-')}/`}
                        hrefLang={i18n.language}
                        className="btn btn-secondary sbtn"
                      >
                        <MdRefresh size={24} />
                      </Link>

                    </div>
                    <div className={cn('action-btn', { invisible: !isShown })}>
                      {linkComponent}
                    </div>
                  </div>
                </div>
              )}
              <div className="item-content--timer">
                <div className="item-content--timer-progress">
                  {isLoading
                    ? <p>{t("Loading...")}</p>
                    : selected
                      ? `${formatDurationDisplay(progress)} / ${trackTime}`
                      : trackTime}
                </div>
              </div>
              {width <= 600 && (
                <div className="item-content--dropdown">
                  <button
                    className="dropdown-button"
                    onClick={handleDropdownClick}
                    ref={activatorRef}
                    aria-label="Toggle Dropdown"
                  >
                    <FontAwesomeIcon icon={faCircleChevronDown} className="icon-dropdown" />
                  </button>
                  <div
                    className="dropdown-content"
                    style={{ display: isOpen ? 'flex' : 'none' }}
                    ref={dropDownRef}
                  >
                    <Link
                      hrefLang={i18n.language}
                      to="/all-reciters"
                      className="link"
                    >
                      {t("Download")}
                    </Link>
                    {linkComponent}
                    {/* Add the "View" link */}
                    <Link
                      href={
                        typeof window !== "undefined"
                          ? `${window.location.pathname}${title.toLowerCase().replace(/ /g, '-')}/`
                          : "#"
                      }
                      hrefLang={i18n.language}
                      className="link"
                    >
                      View
                    </Link>
                  </div>
                </div>
              )}
            </div>
            {selected && (
              <div className="progress-container">
                <AudioProgressBar duration={duration} currentProgress={progress} />
              </div>
            )}
          </li>
        </ul>
      </div>
    </LazyLoad>
  );
};

export default TrackItem;
